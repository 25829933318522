
      @use 'sass:math';
       @import "~@JohnLewisPartnership/jl-design-system/dist/style/typography/config/_john-lewis.scss";
      @import "~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/_variables.scss";
      @import "~@JohnLewisPartnership/jl-design-system/dist/style/typography/_display-as.scss";
            @import '@JohnLewisPartnership/jl-design-system/dist/scss/core/config';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid-utils';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/utilities';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/forms';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/animation';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/focus';
    
@import "@JohnLewisPartnership/jl-design-system/dist/style/theme/john-lewis.scss";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @extend %theme-john-lewis;
}

.main {
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  background-color: $colour-taupe-20;
}

.tooltipPopover {
  left: -20px;

  @include breakpoint(xs) {
    left: 0px;
  }
}

// IE Summary polyfill
details > summary::before {
  content: "" !important;
}

details[open] > summary::before {
  content: "" !important;
}

.parcellab-styles * {
  @include display-as("body-m");
}

.pl-box {
  border: none !important;
  border-radius: 0 !important;
}

.pl-box-cal-heading {
  @include display-as("heading-xs");
  color: var(--heading-xs-color, #141414) !important;
  font-family: var(
    --heading-xs-font-family,
    GillSansforJL,
    Gill Sans,
    Gill Sans MT,
    GillSansMTStd-Medium,
    Avenir,
    Corbel,
    Arial,
    Helvetica,
    sans-serif
  ) !important;
  font-size: var(--heading-xs-font-size, font-size-heading-extra-small) !important;
  font-weight: var(--heading-xs-font-weight, 600) !important;
  line-height: var(--heading-xs-line-height, 22px) !important;
  letter-spacing: var(--heading-xs-letter-spacing, normal) !important;
  margin-top: var(--heading-xs-margin-top, 0) !important;
  text-transform: var(--heading-xs-text-transform, none) !important;
}

.pl-cal-day {
  // @include display-as("heading-xl");
  color: var(--heading-xl-color, #141414) !important;
  font-family: var(
    --heading-xl-font-family,
    GillSansforJL,
    Gill Sans,
    Gill Sans MT,
    GillSansMTStd-Medium,
    Avenir,
    Corbel,
    Arial,
    Helvetica,
    sans-serif
  ) !important;
  font-size: var(--heading-xl-font-size, 48px) !important;
  font-weight: var(--heading-xl-font-weight, 500) !important;
  line-height: var(--heading-xl-line-height, 48px) !important;
  letter-spacing: var(--heading-xl-letter-spacing, normal) !important;
  margin-top: var(--heading-xl-margin-top, 0) !important;
  text-transform: var(--heading-xl-text-transform, none) !important;
}

.pl-cal-week-day {
  @include display-as("heading-xl");
  font-weight: 400 !important;
  color: $colour-black !important;
}

.pl-cal-month {
  @include display-as("heading-m");
  font-weight: 400 !important;
}

.pl-time-data {
  @include display-as("heading-xs");
  text-align: center !important;
}

.pl-time-data img {
  display: none !important;
}

.pl-prediction-caption {
  color: $colour-black !important;
}

#pl-reroute-link {
  background-color: #141414 !important;
  @include display-as("heading-xs");
  border: none !important;
  border-radius: 0 !important;
  padding: 10px 12px;

  & > div {
    color: $colour-white !important;

    & > img {
      display: none !important;
    }

    &:last-child {
      display: none !important;
    }
  }

  &:hover {
    background-color: $colour-accent !important;
  }
}

.pl-main .pl-box-body {
  background-color: $colour-white !important;
  padding-top: 24px !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
  margin-bottom: 24px !important;
}

.pl-box-address-caption {
  @include display-as("heading-xs");
}

.pl-vote b {
  @include display-as("heading-xs");
}

.pl-hr-icon-left,
.pl-hr-icon-center,
.pl-hr-icon-right {
  background-color: #d9d9d9;
}

.pl-checkpoint {
  padding: 16px 12px !important;
  border-bottom: none !important;
  margin-bottom: spacing() !important;
}

.pl-icon-hr-container + .pl-checkpoint {
  background-color: #f1f7fc;
  border-left: 3px solid #4891d6;

  &:nth-child(2) b {
    @include display-as("heading-xs");
  }
}

#pl-show-more-button {
  color: $colour-black !important;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    width: 30%;
    height: spacing(2);
    background-position: center;
    border-bottom: 1px solid $colour-black;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 7px;
    width: 35%;
    height: spacing(2);
    background-position: center;
    border-bottom: 1px solid $colour-black;
  }
}

#pl-courier-fwd-link {
  @include display-as("heading-xs");
  background-color: $colour-taupe-20 !important;
  color: $colour-black !important;
  border: none !important;
  border-radius: 0 !important;
  text-decoration: underline;
  text-align: left;
  padding: 0 !important;
  padding-left: 20px !important;
}

#pl-courier-fwd-link div {
  @include display-as("heading-xs");
  color: #6f6f6f !important;
  text-decoration: none !important;
  margin-top: spacing(2);
  border: none !important;
}

.pl-box-location-heading {
  @include display-as("heading-m");
}

.pl-box-articles .pl-box-heading {
  background-color: $colour-white !important;
  text-align: left !important;
}

.pl-box-articles {
  background-color: $colour-white !important;
}

.pl-box-articles .pl-box-body {
  background-color: $colour-white !important;
}

.pl-article-list li {
  border-bottom: none !important;
}

.pl-article-description {
  @include display-as("heading-xs");
  color: $colour-black !important;
}

.pl-icon-hr {
  border-bottom: 1px dashed $colour-grey-20 !important;
}

.pl-order-no,
.pl-order-sub {
  display: none !important;
}

.pl-box-heading {
  background-color: $colour-white !important;
}

.pl-hr-icon {
  background-color: $colour-white !important;
}

.pl-hr-icon .pl-hr-icon-center {
  padding: spacing(3) !important;
}

.pl-button {
  border-radius: 2px !important;
}

.pl-tab-container .pl-button {
  background-color: $colour-taupe-20 !important;
}

.pl-header {
  overflow-x: auto !important;
}

.pl-tab-container {
  display: flex;
  width: 100% !important;
  overflow: auto;
  margin-left: 0px !important;
  margin-right: 0px !important;

  @include breakpoint(small) {
    display: block;
    width: 100% !important;
    max-width: 100% !important;
  }

  // .pl-tab-content:first-child {
  //   margin-left: 0px !important;
  // }
}

// .pl-tab-container+.pl-tab-content {
//   :first-child {
//     background-color: #4891D6 !important;
//   }
// }

.pl-tab-container .pl-col-4,
.pl-tab-container .pl-col-6,
.pl-tab-container .pl-col-8 {
  width: 210px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;

  @include breakpoint(small) {
    width: 350px !important;
  }
}

.pl-tab-container {
  .pl-col-6:first-child {
    .pl-tab .pl-tab-content {
      margin-left: 0 !important;
    }
  }
}

.pl-tab {
  padding: 0px !important;
  margin-right: 0px !important;
  margin-bottom: spacing(0) !important;

  @include breakpoint(small) {
    margin-bottom: spacing(2) !important;
  }
}

.pl-active .pl-tab-content {
  border-bottom: 4px solid $colour-accent;
  background-color: white;
  padding: spacing(2);
  margin-left: spacing(1);
}

.pl-not-active {
  opacity: 1 !important;
}

.pl-not-active .pl-tab-content {
  cursor: pointer;
  border-radius: 2px;
  padding: spacing(2);
  background-color: white;
  border-bottom: 4px solid white;
  margin-left: spacing(1);

  &:hover {
    transition: all 0.12s linear;
    background-color: white;
    border-bottom-color: white;
  }
}

.pl-tab-text {
  padding-right: 0px !important;
}

.pl-tab-text-tno {
  @include display-as("heading-xs");
  word-wrap: break-word !important;
  white-space: break-spaces !important;

  @include breakpoint(small) {
    word-wrap: normal !important;
    white-space: normal !important;
  }
}

.pl-tab-text-status {
  @include display-as("heading-xs");
}

.pl-status {
  display: none !important;
}
