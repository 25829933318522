
      @use 'sass:math';
       @import "~@JohnLewisPartnership/jl-design-system/dist/style/typography/config/_john-lewis.scss";
      @import "~@JohnLewisPartnership/jl-design-system/dist/style/colour/john-lewis/_variables.scss";
      @import "~@JohnLewisPartnership/jl-design-system/dist/style/typography/_display-as.scss";
            @import '@JohnLewisPartnership/jl-design-system/dist/scss/core/config';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/breakpoints';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/grid/grid-utils';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/utilities';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/forms';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/animation';
      @import '~@JohnLewisPartnership/jl-design-system/dist/scss/core/focus';
    
// ------------------------
// Layout
// ------------------------
.util-container {
  max-width: $grid-max-width;
  margin: 0 auto;
  padding: 0 $grid-gutter-small;

  @include breakpoint(small) {
    padding: 0 $grid-gutter-large;
  }
}

.util-grid {
  @include grid();
}

.util-centred {
  text-align: center;
}

// ------------------------
// Typography
// ------------------------
.util-headline {
  @include display-as("heading-xl");
}

.util-title {
  @include display-as("subtitle-m");
}

.util-section {
  @include display-as("capitals");
}

.util-label {
  @include display-as("body-m");
}

.util-body {
  @include display-as("body-m");
}

.util-sectionHeading {
  @include display-as("heading-m");
  text-align: center;
  margin-bottom: spacing();
  font-weight: $font-weight-bold;
}

.util-pageHeading {
  padding: 30px spacing(2);
  border-bottom: 1px solid $colour-grey-15;
  text-align: center;
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: spacing(4);
  font-weight: $font-weight-bold;

  @include breakpoint(small) {
    margin-bottom: spacing(5);
  }

  @include breakpoint(medium) {
    margin-bottom: spacing(6);
  }
}

.util-visuallyHiddenText {
  @extend %visuallyHiddenText;
}

.util-dividerContainer {
  @include grid-col(12);
  @include breakpoint(medium) {
    @include grid-col(8);
    @include grid-col-offset-left(2);
  }
}

.util-dividerWithText {
  @include display-as("heading-s");
  display: flex;
  align-items: center;
  text-align: center;
  margin: spacing(6) 0;

  @include breakpoint(xs) {
    @include display-as("heading-m");
    margin: spacing(6) 0;
  }

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid $colour-grey-15;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
}

.page-heading {
  @include display-as("heading-m");
  text-align: center;
  padding-top: spacing(4);
  padding-bottom: spacing(1);

  @include breakpoint(xs) {
    @include display-as("heading-l");
  }
}

.dividerWithText {
  @include display-as("heading-s");
  display: flex;
  align-items: center;
  text-align: center;
  margin: spacing(6) 0;

  @include breakpoint(xs) {
    @include display-as("heading-m");
    margin: spacing(6) 0;
  }

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid $colour-grey-15;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
}
